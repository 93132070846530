import { SVGProps } from 'react';

function Tiktok({
  fill = 'var(--neutral-0)',
  width = 20,
  height = 20,
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_778_21824"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x={2}
        y={2}
        width={21}
        height={20}
      >
        <path d="M22.3335 2H2.3335V22H22.3335V2Z" fill="white" />
      </mask>
      <g mask="url(#mask0_778_21824)">
        <path
          d="M18.4489 6.00894C17.3716 5.30651 16.594 4.1826 16.3515 2.87248C16.299 2.58939 16.2703 2.29806 16.2703 2H12.8319L12.8263 15.78C12.7685 17.3231 11.4985 18.5616 9.94153 18.5616C9.45762 18.5616 9.00196 18.4406 8.60081 18.2295C7.68081 17.7453 7.0512 16.781 7.0512 15.6713C7.0512 14.0775 8.34786 12.7808 9.94153 12.7808C10.239 12.7808 10.5244 12.8299 10.7944 12.9145V9.40422C10.515 9.36621 10.2312 9.34241 9.94153 9.34241C6.4518 9.34241 3.61279 12.1815 3.61279 15.6713C3.61279 17.8124 4.68255 19.7072 6.3146 20.853C7.34255 21.5747 8.59295 22 9.94153 22C13.4312 22 16.2703 19.161 16.2703 15.6713V8.68365C17.6189 9.6516 19.2711 10.222 21.054 10.222V6.78359C20.0936 6.78359 19.1991 6.49808 18.4489 6.00894Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}

export default Tiktok;
